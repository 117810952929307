<template>
  <pre>
  hello from the new component
    {{ props }}
  </pre>
</template>
<script setup lang="ts">
interface Props {
  data: { speed: number; titleEnd?: string; titleStart?: string }
}

const props = defineProps<Props>()
</script>
